<template>
    <div class="zt-page-content">
        <ItemApply @shareState="shareState"></ItemApply>
        <div v-if="shareItem && shareItem.stat==2" class="zt-block flex flex-align-center" style="padding:20px">
            <div class="flex-1">
                <el-select style="width:140px;margin-right:60px;"
                    v-model="queryOpt.stat" placeholder="请选择">
                    <el-option
                    v-for="item in options"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                    </el-option>
                </el-select>
                <el-date-picker @change="change_time"
                    style="width:280px"
                    v-model="timeValue"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                </el-date-picker>
                <!-- <el-checkbox @change="changeIsSelf()" v-model="queryOpt">只显示自己</el-checkbox> -->
            </div>
            <div>
                <el-button @click="invite()" size="small" type="primary">邀请代理人</el-button>
            </div>
        </div>
        <div v-if="shareItem && shareItem.stat==2" class="zt-block flex" style="height:calc(100% - 140px);background:transparent;box-shadow: none;">
            <div class="left flex-1" style="width:calc(100% - 326px);margin-right:16px">
                <el-table v-if="list && list.length>0" :data="list" row-key="id" style="width:100%" border @row-click="detail" :highlight-current-row="item?true:false"
                    :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
                    <el-table-column prop="contact_name" label="代理人">
                        <!-- <template slot-scope="scope">
                            <div>{{scope.row.name}}</div>
                        </template> -->
                    </el-table-column>
                    <!-- <el-table-column prop="wx" label="微信名">
                        <template slot-scope="scope">
                            <div v-if="scope.row.wx && scope.row.wx.length>0">{{scope.row.wx[0].name}}</div>
                            <div v-else>未授权</div>
                        </template>
                    </el-table-column> -->
                    <el-table-column prop="name" label="店铺名称">
                    </el-table-column>
                    <el-table-column prop="stat" label="状态">
                        <template slot-scope="scope">
                            <div v-if="scope.row.stat==1" style="color:#24B1EB;">已申请</div>
                            <div v-if="scope.row.stat==2" style="color:#2BBAAE;">已通过</div>
                            <div v-if="scope.row.stat==3" style="color:#F66F6A;">已拒绝</div>
                            <div v-if="scope.row.stat==4" style="color:#2BBAAE;">已启用</div>
                            <div v-if="scope.row.stat==5" style="color:#F66F6A;">已禁用</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="statistics" label="累计交易额">
                        <template slot-scope="scope">
                            <div v-if="scope.row.statistics">￥{{scope.row.statistics.amount | priceFilt}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="statistics" label="获得收益">
                        <template slot-scope="scope">
                            <div v-if="scope.row.statistics">￥{{scope.row.statistics.profit | priceFilt}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="statistics" label="订货单">
                        <template slot-scope="scope">
                            <div v-if="scope.row.statistics">{{scope.row.statistics.count}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="address" label="操作" width="150">
                        <template slot-scope="scope">
                            <!-- <el-button @click="detail(scope.row)" type="text" size="small">
                               查看详细信息
                            </el-button> -->
                            <el-button v-if="scope.row.lv>1 && scope.row.stat==2 || scope.row.lv>1 && scope.row.stat==4" @click="check(scope.row,'禁用代理')" style="color:#F66F6A;" type="text" size="small">
                               禁用代理
                            </el-button>
                            <el-button v-if="scope.row.lv>1 && scope.row.stat==5" @click="check(scope.row,'恢复启用')" type="text" size="small">
                               恢复启用
                            </el-button>
                            <el-button v-if="scope.row.lv>1 && scope.row.stat==1" @click="check(scope.row,'批准通过')" style="color:#2BBAAE;" type="text" size="small">
                                批准通过
                            </el-button>
                            <el-button v-if="scope.row.lv>1 && scope.row.stat==1" @click="check(scope.row,'驳回')" style="color:#F66F6A;" type="text" size="small">
                                驳回
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="right">
                <div><b>代理人详细信息</b></div>
                <div v-if="item">代理商级别：
                    <span v-if="item.lv==1">一级</span>
                    <span v-if="item.lv==2">二级</span>
                    <span v-if="item.lv==3">三级</span>
                </div>
                <div v-else>代理商级别：无</div>
                <div v-if="item">手机号：{{item.contact_mobile}}</div>
                <div v-else>手机号：无</div>
            </div>
        </div>

        <el-dialog width="550px" :title="popTitle" :visible.sync="popVisible" :destroy-on-close="true">
            <div class="invite">
                <div class="wx_code flex flex-center">
                    <img style="position:relative;top:-35px
                            transform:scale(0.6);
                            -ms-transform:scale(0.6);
                            -moz-transform:scale(0.6);
                            -webkit-transform:scale(0.6);
                            -o-transform:scale(0.6);"
                    :src="wx_code"/>
                </div>
                <div class="wx_text">
                    <div>扫码进入小程序，将小程序链接分享给代理人</div>
                </div>
                <div class="flex flex-justify-around" style="padding: 30px 140px 10px;">
                    <el-button @click="popVisible=false" style="width:76px;" size="small" type="primary" plain>取消</el-button>
                    <el-button @click="popVisible=false" style="width:76px;" size="small" type="primary">确定</el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { fetchTree } from 'util'
import { debounce } from 'lodash';
import { listAgent, statAgent, getStoreWxqr } from '@/service/sharer';
import ItemApply from './ItemApply.vue';
export default {
    components: {
        ItemApply
    },
    data() {
        return {
            popTitle:null,
            popVisible:false,
            options:[
                {id:0,name:"全部"},
                {id:1,name:"已申请"},
                {id:2,name:"已通过"},
                {id:3,name:"已拒绝"},
                {id:4,name:"已启用"},
                {id:5,name:"已禁用"},
            ],
            queryOpt:{
                stat: 0,
                tm_start:null,
                tm_end: null,
                page:1,
                pagesize:999,
            },
            total: 15,
            timeValue:[],
            list:[],
            item:null,
            wx_code:null,
            shareItem:null,
        }
    },
    watch: {
        queryOpt: {
            handler() {
                this.debounceRefreshItems();
            },
            deep: true
        },
    },
    created() {
        this.debounceRefreshItems = debounce(this.refreshItems, 500);
    },
    mounted() {
        this.refreshItems();
    },
    methods: {
        shareState(item) {
            this.shareItem = item;
        },
        refreshItems() {
            console.log('refreshItems');
            this.list = [];
            listAgent(this.queryOpt).then(rst => {
                // this.total=rst.total;
                // this.list = fetchTree(rst.list, 1, { name: { dest: 'name' }, id: { dest: 'id' }, parent: 'lv' });
                this.list = rst.list;
                // console.log(this.list);
            }).catch(err => {
                console.log(err);
                this.$message.error(err.message);
            });
        },
        change_time(e) {
            console.log(e);
            // this.queryOpt.page = 1;
            if (e) {
                this.queryOpt.where.tm_start = new Date(e[0]).getTime();
                this.queryOpt.where.tm_end = new Date(e[1]).getTime();
            } else {
                this.queryOpt.where.tm_start = null;
                this.queryOpt.where.tm_end = null;
            }
        },
        invite() {
            this.popTitle = "邀请代理人";
            this.popVisible = true;
            this.get_wx_code();
        },
        get_wx_code() {
            let obj = {
                fx_product_id:0,
                page_uri: "pages/index/index",
                img_width: "100px",
            };
            getStoreWxqr(obj).then(rst => {
                // console.log("1111111", rst);
                this.wx_code = "data:image/png;base64," + rst.img;
            }).catch(err => {
                console.log(err);
                // this.$message.error(err.message);
            });
        },
        detail(item) {
            this.item = {
                contact_mobile: item.contact_mobile,
                lv: item.lv
            };
        },
        // 审批 状态，1已申请，2已通过，3已拒绝，4已启用，5已禁用
        check(item,type) {
            let obj = {
                id: item.id,
                stat: item.stat
            };
            if (type == '批准通过') {
                obj.stat = 2
            } else if (type == '驳回') {
                obj.stat = 3
            } else if (type == '禁用代理') {
                obj.stat = 5
            } else if (type == '恢复启用') {
                obj.stat = 4
            }
            this.$confirm("是否确定"+type+"？", "温馨提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                closeOnClickModal: false,
                type: "warning",
            }).then(() => {
                statAgent(obj).then(rst => {
                    this.refreshItems();
                    this.$message.success(type+"成功");
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            });
        }
    }
}
</script>
<style scoped>
    .zt-block>>> .el-date-editor .el-range-separator {
        width: 10%;
    }
    .zt-block>>> .el-table .cell {
        text-align: center;
    }
    .right {
        width: 280px;
        padding: 10px 30px;
        background: #fff;
        font-size: 16px;
        color: #4D4D4D;
        line-height: 22px;
    }
    .right>div {
        margin-bottom: 20px;
    }
    .right>div:first-child {
        font-size: 18px;
        padding-top: 10px;
    }
    .wx_code {
        width: 100%;
        height: 220px;
        overflow: hidden;
    }
    .wx_text {
        text-align: center;
        font-size: 16px;
        color: #292929;
    }
</style>